import PropTypes from "prop-types"
import React from "react"
import bootstrap from "../components/bootstrap.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Helmet } from "react-helmet"

const Breadcrumbs = ({ tags, post, tag, type }) => {

  const breadcrumbs = []
  breadcrumbs.push({ url: '/', name: 'Fintek.pl' })
  if(type === 'event') breadcrumbs.push({ url: '/wydarzenia/', name: 'Eventy' })
  if(tags && tags[0]) breadcrumbs.push({ url: '/'+(tags[0].parent_slug ? tags[0].parent_slug + '/' : '')+tags[0].slug+'/', name: tags[0].name })
  if(post && post.post_title) breadcrumbs.push({ url: '/'+post.post_name+'/', name: post.post_title })
  if(tag && tag.name) breadcrumbs.push({ url: (tag.parent_slug ? '/' + tag.parent_slug : '' ) + '/'+tag.slug+'/', name: tag.name })

  const listItem = []
  breadcrumbs.forEach((e,i)=>{
    listItem.push({
      "@type": "ListItem",
      "position": i+1,
      "item": {
       "url": e.url,
       "@id": e.url,
       "@type": "WebPage",
       "name": e.name
      }
     })
  })










const schema = {
  "@context": "https://schema.org",
  "@graph": [
   {
    "url": "https://fintek.pl/",
    "@id": "https://fintek.pl/#organization",
    "@type": "Organization",
    "sameAs": [
     "https://www.facebook.com/fintekpl/", "https://twitter.com/Fintek_pl", "https://www.linkedin.com/company/fintek.pl", "https://www.youtube.com/channel/UCThB_tc44DGaGhzSMW_JZdg", "https://www.instagram.com/fintek.pl/"],
    "name": "fintek.pl",
    "logo": {
     "url": "https://fintek.pl/assets/logo.png",
     "@id": "https://fintek.pl/#logo",
     "@type": "ImageObject",
     "width": 142,
     "inLanguage": "pl-PL",
     "height": 51,
     "caption": "fintek.pl"
    }
   },{
    "url": "https://fintek.pl/",
    "@id": "https://fintek.pl/#website",
    "@type": "WebSite",
    "description": "fintek.pl",
    "potentialAction": [
     {
      "@type": "SearchAction",
      "target": "https://fintek.pl/szukaj/?s={search_term_string}",
      "query-input": "required name=search_term_string"
     }],
    "inLanguage": "pl-PL",
    "publisher": {
     "@id": "https://fintek.pl/#organization"
    },
    "name": "fintek.pl"
   },{
    "@id": breadcrumbs.slice(-1)[0].url + "#breadcrumb",
    "@type": "BreadcrumbList",
    "itemListElement": listItem
   }]
 }


return (
  
  <>
  <div className={[bootstrap.dNone,bootstrap.dMdBlock].join(' ')}>
    <ul className={['breadcrumbs','font-serif-light',bootstrap.breadcrumb,bootstrap.mb3].join(' ')}> 
      {breadcrumbs.map((node, i) => (
        <li key={i} className={bootstrap.breadcrumbItem}>
          {(i<breadcrumbs.length-1) ? <AniLink fade to={node.url}>{node.name}</AniLink> : <span>{node.name}</span>}
        </li>
      ))}
    </ul>
  </div>
  <Helmet>
    <script type="application/ld+json">{JSON.stringify(schema)}</script>
  </Helmet>
  </>

)


}

Breadcrumbs.propTypes = {
  tags: PropTypes.array,
  post: PropTypes.object,
  tag: PropTypes.object,
  type: PropTypes.string,
}

Breadcrumbs.defaultProps = {
  tags: [],
  tag: null,
  post: null,
  type: null,
}

export default Breadcrumbs
